import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ShieldIcon from "@mui/icons-material/Shield";
import AllBlockersIcon from "../../../SvgIcons/AllBlockersIcon";
import PersonIcon from "../../../SvgIcons/PersonIcon";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import VAIcon from "../../../SvgIcons/VALogo";

const tester_dashboard_menu = [
    {
        path: "/tester/dashboard",
        icon: HomeIcon,
        label: "My Dashboard"
    },
    {
        path: "/tester/customers",
        icon: PersonIcon,
        label: "My Customers"
    },
    {
        path: "/tester/all_assets",
        icon: WysiwygIcon,
        label: "All Assets"
    },
    {
        path: "/tester/vulnerability_assessment",
        icon: VAIcon,
        label: "Vul. Assessments",
    },
    {
        icon: ShieldIcon,
        label: "Pentests",
        child: [
            {
                path: "/tester/all_pentests",
                label: "All Pentests"
            },
            {
                path: "/tester/pentests/inflight",
                label: "Inflight"
            },
            // {
            //     path: "/tester/pentests/retest",
            //     label: "Retests"
            // },
            {
                path: "/tester/pentests/closed",
                label: "Closed"
            },
            {
                path: "/tester/pentests/upcoming",
                label: "Upcoming"
            },
            // {
            //     path: "/tester/pentests/pending",
            //     label: "Pending Approval"
            // },
            {
                path: "/tester/all_blockers",
                // icon: AllBlockersIcon,
                label: "All Blockers"
            },
        ],
    },
    {
        path: "/tester/all_findings",
        icon: SearchIcon,
        label: "All Findings"
    },
    {
        path: "/tester/chat",
        icon: ChatBubbleOutlineIcon,
        label: "Chat"
    }
]
export default tester_dashboard_menu;
